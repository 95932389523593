.Logout-button {
  margin: 0rem 0.625rem 0.625rem 0.625rem;
  position: absolute;
  bottom: 0;
}

.sidenav {
  overflow: hidden;
  height: 100%;
  width: 0rem;
  position: fixed;
  top: 0rem;
  left: 0rem;
  background-color: lightslategray;
  transition: 0.5s;
  padding-top: 0.625rem;
  z-index: 3;
}


.hide-menu {
  width: 0;
}

.show-screen {
  display: inline;
}

.hide-screen {
  display: none;
}

/* Mobile version of side bar menu */
@media (max-width: 719px) {
  .Menu.top-menu,
  .Menu-treeView .nodeText,
  .btn.Logout-button,
  .arrow-menu {
    font-size: 1rem;
  }
  .show-menu {
    width: 100%;
  }
  .Logout-button {
    width: 95%;
  }
}

/* Desktop version of side bar menu */
@media (min-width: 720px) {
  .Menu.top-menu,
  .Menu-treeView .nodeText,
  .btn.Logout-button,
  .arrow-menu {
    font-size: 1.2rem;
  }
  .show-menu {
    width: 30%;
  }
  .Logout-button {
    width: 90%;
  }
}

.Menu {
  transition: margin-left 0.5s;
  padding: 1rem;
  height: 100%;
}

.Menu.top-menu {
  width: 100%;
  height: 5rem;
  background-color: lightslategray;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
}

.Menu.top-menu .col-menu {
  padding-right: 10px;
  padding-left: 10px;
}

.Menu .btn-menu {
  font-size: 2rem;
  color: white;
}

.current-screen {
  color: white;
  text-align: center;
}
.current-screen-text {
  font-weight: bold;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.Menu .btn-menu:hover {
  color: gray;
  cursor: pointer;
}

.arrow-menu:hover {
  color: white;
  cursor: pointer;
}
.arrow-menu {
  float: right;
  margin-right: 1rem;
  color: white;
}

.Menu-treeView {
  max-height: 90%;
  overflow-x: hidden;
}

.Menu-treeView .nodeText {
  border-radius: 0.2rem;
  padding: 0.5rem;
  padding-top: 0.25rem;
  border: 0.1rem solid transparent;
  color: white;
}

.Menu-treeNode .nodeText:hover {
  border-color: #4d758c;
  color: white;
  background-color: #4d758c;
}

.Menu-treeLeaf .nodeText:hover {
  border-color: #4d758c;
  color: white;
  background-color: #4d758c;
  text-decoration: underline;
  cursor: pointer;
}

.Menu-treeView div.k-animation-container {
  display: block;
}

.Menu-treeNode {
  display: block;
  margin-left: 0.5rem;
  padding: 0.5rem;
}

.Menu-treeLeaf {
  display: block;
  margin-left: 1rem;
  padding: 0.5rem;
}
