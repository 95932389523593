.route {
  display: inline-block;
}


.box {
  border: 2px solid gray;
  border-radius: 4px;
  margin: 10px 10px 10px 10px;
  position: relative;  
}

.highlighted .box {
  box-shadow: inset 0 0 4px #00f;
}

.box button.btn,
.box .box-footer span{
  font-size: 0.8rem !important;
}

.box .box-title{
  text-align: center;
}

.box hr{
  border: 1px solid gray;
  margin: 0;
}

.box .box-body{
  width: 320px;
  min-height: 200px;
  position: relative;
  transition: 0.5s;  
}

.box .box-body.minimized{
  height: 30px;
  min-height: 30px;
  transition: 0.5s;
}

.box .box-body.super-minimized{
  height: 0px;
  min-height: 0px;
  transition: 0.5s;
}

.box .box-footer:not(.minimized){
  max-height: 100px;
}

.box .box-footer.super-minimized{
  height: 0px;
}

.box .box-footer{
  padding: 2px;
  margin:2px
}

label.label-margin{
  margin: 5px 10px 0 10px;
}

span.delete-run{
  margin-right: 5px;
}

span.delete-run:hover{
  cursor: pointer;
}

div.custom-backdrop{
  z-index: 1050;
}

.map-container > div{
  position: relative !important;
}

.color-picker{
  position: absolute; 
  z-index: 1;
  margin-top: 40px;
  margin-left: 320px;
}

.color-picker-button,
.minimize-button,
.super-minimize-button,
.move-left-button{
  cursor: pointer;
  color: rgb(43, 41, 41);
  margin-right: 5px;
  margin-left: 5px;
}

@media (hover: hover) {
  .color-picker-button:hover,
  .minimize-button:hover,
  .super-minimize-button:hover,
  .move-left-button:hover{
    color: rgb(126, 122, 122);
  }
}


button.btn-auto-route {
  margin: 3px;
}

.checkbox-group input[type="checkbox"]{
  display: inline-block;
  margin-top: 0.25rem;
  vertical-align: top;
}

.checkbox-group input[type="range"]{
  display: inline-block;
  margin-top: 0.25rem;
  vertical-align: top;
  width: 5rem;
  height: 1rem;
}

.checkbox-group label{
  margin-left: 0.5rem;
}

.edit-routing-note{
  width: 100%;
  resize: none;
}


table, td, th {
  border: 1px solid;
}

table {
  width: 100%;
  border-collapse: collapse;
}

div.orders-labels{
  width: auto;
}

div.orders-labels, div.orders-labels span{
  font-size: 1rem;
}
div.outer.order-label{
  height: 1rem;
  width: 1rem;
  display: inline-block;
  vertical-align: bottom;
}

div.dropdown-menu{
  margin: 0;
}
div.dropdown button {
  width: 100%;
  border: 1px solid #ced4da;
}

.pac-container {
  z-index: 2001;
}