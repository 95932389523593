.box_item_component input[type="checkbox"]{
    margin: 2px;
}

.box_item_component .item.pickup {
    width: 205px;
}

.box_item_component .outer.shipped{
    background-color: #ccc569;
}

.item-options.pickup{
    width: 80px;   
}