body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (min-width: 720px) {
  body,
  body .btn,
  body .form-control,
  body label,
  body p,
  body span,
  .typeahead-upper .dropdown-menu .dropdown-item {
    font-size: 1rem;
  }
}

@media (max-width: 719px) {
  body,
  body .btn,
  body .form-control,
  body label,
  body p,
  body span,
  .typeahead-upper .dropdown-menu .dropdown-item {
    font-size: 1rem;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.page-container {
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.full-size {
  width: 100%;
  height: 100%;
}

.custom-row {
  display: flex;
  /* flex-wrap: wrap; */
}

.bottom-inputMargin {
  margin-bottom: 1rem;
}
.top-inputMargin {
  margin-top: 0.5rem;
}

.checkbox-group input[type="checkbox"] {
  width: 1.5rem;
  height: 1.5rem;
}

.btn-square {
  width: 4rem;
}

input.invalid,
select.invalid,
.typeahead-upper.invalid input {
  color: #495057;
  background-color: #fff;
  border-color: #f15c49;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 8, 0, 0.25);
}

span.input-error {
  color: #f15c49;
}

label.label-disable {
  color: lightgray;
}

label.label-title-forInput {
  margin-top: 0.5rem;
}

label.label-title-forSpan {
  margin: 0;
}

label.label-descriptionText {
  padding-left: 0.9rem;
  margin: 0;
}

label.label-descriptionText span {
  display: inline-block;
}

div.modal-content {
  border: 0;
}
div.modal-header {
  background-color: #4e73df;
  color: white;
}
.modal-spinner {
  display: flex !important;
}
.modal-spinner .modal-dialog .modal-content {
  display: block;
  text-align: center;
  width: 12rem;
  height: 6rem;
  padding-top: 1.5rem;
  background-color: #4e73df;
  color: white;
}
.modal-spinner .modal-dialog {
  margin: 1.75rem auto;
}
.modal-spinner-text {
  margin-left: 1rem;
  user-select: none;
}
div.modal-footer {
  justify-content: center;
}

.typeahead-upper input {
  text-transform: uppercase;
}
.typeahead-upper .dropdown-menu {
  min-width: 0;
}
.typeahead-upper .dropdown-menu .dropdown-item {
  padding: 0.25rem 0.75rem;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.lookup-table tbody {
  display: block;
  /* height: 20rem; */
  max-height: 20rem;
  overflow-y: scroll;
}

.lookup-table thead,
.lookup-table tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

table.lookup-table tbody tr.selected {
  background-color: hsl(202, 28%, 41%);
  color: white;
}

.align-text-right {
  text-align: right;
}
/*Define width of columns in Table  and align*/

.vertical-align-text {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.pdf-viewer-container{
  height: calc(75vh);
}
.pdf-viewer {
  width: 100%;
  height: 100%;
}

div.react-datepicker-wrapper{
  width: auto;
}
