.invoice-list-item:hover{
    cursor: pointer;
    color: gray;
    text-decoration: underline;
}

.invoice-pdf-link:hover{
    cursor: pointer;
    color: gray;
    text-decoration: underline;
}
.invoice-pdf-link{
    color: cornflowerblue;
    text-decoration: underline;
}
