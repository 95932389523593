.Login-page {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: block;
}

.Login-container {
  display: flex;
  align-items: center;
  position: relative;
  margin: 1.75rem auto;
  width: auto;
  max-width: 450px;
  min-height: calc(100% - 3.5rem);
}

.Login-panel {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem;
  margin: 1rem;
  /* border: 1px solid; */
  border-color: gray;
  border-radius: 1%;
  /* background-color: #5f6069; */
  box-shadow: 0 1rem 3rem #6e707e !important;
}

.Login-top {
  text-align: center;
}

.Login-button {
  display: flex;
  align-items: center;
  justify-content: center;
}
