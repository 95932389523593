.custom-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 2rem;
  background-color: lightslategray;
  color: white;
  box-shadow: 0 1rem 3rem #6e707e !important;
}

.custom-span-footer {
  font-size: small;
}

.span-customLeft {
  float: left;
  margin-left: 10px;
}

.span-customRight {
  float: right;
  margin-right: 10px;
}
