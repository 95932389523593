.box_item_component {
    width: 100%;
    transition: opacity 0.5s ease-in;
    visibility: visible;
    opacity: 1;
}

.item.locked {
    cursor: not-allowed !important;
}

.box_item_component.hidden {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.1s ease-out;
}

.box_item_component .outer {
    border-top: none;
    width: 310px;
    overflow: hidden;
    background-color: #005577;
    border-radius: 5px;
    padding: 2px;
    margin: 2px;
}

.box_item_component .highlighted .outer {
    border-top: 40px solid #ddd;
}

.box_item_component .item {
    color: white;
    border-radius: 5px;
    padding: 5px 0 5px 0;
    font-size: 0.8rem;
    display: inline-block;
    width: 245px;
}
.box_item_component .item span {
    font-size: 0.8rem;
}

.box_item_component .outer.overdue{
    background-color: #b64435;
}

.box_item_component .outer.future{
    background-color: #61975c;
}

.box-item-option.box-item-click:hover {
    cursor: pointer;
    color: cornflowerblue;
}
.item-options{
    display: inline-block;
    text-align: end;   
    width: 40px; 
}
.box-item-option {
    margin: 1px;
    display: inline-block;
}
.box-item-option {
    margin: 1px;
    display: inline-block;
}
.box-item-grayed-out{
    color: gray !important;
}